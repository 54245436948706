<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo-clic.png" height="300rem">
    <div>Esta página não existe.</div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/Home.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>

.home {
  text-align: center;
}
</style>
